<script>
	import { page } from '$app/stores';
	import AppShellFooter from '$lib/landing-pages/app-shell/footer/Footer.svelte';
	import LandingPageHeader from '$lib/landing-pages/app-shell/header/Header.svelte';
	import NavigationIndicator from '@tickrr/ui/components/v2/elements/loaders/NavigationIndicator.svelte';
	import AppShell from '@tickrr/ui/layouts/AppShell.svelte';
	import { track } from '@vercel/analytics';

	const PAGES_TO_OMIT_HEADER_AND_FOOTER = [
		'/checkout-cancel',
		'/checkout-success',
		'/checkout-complete'
	];
</script>

<AppShell>
	<svelte:fragment slot="navigationIndicator">
		<NavigationIndicator />
	</svelte:fragment>

	<!-- Router Slot -->
	{#if !PAGES_TO_OMIT_HEADER_AND_FOOTER.includes($page.url.pathname)}
		<LandingPageHeader />
	{/if}
	<slot />
	<!-- ---- / ---- -->

	<svelte:fragment slot="pageFooter">
		{#if !PAGES_TO_OMIT_HEADER_AND_FOOTER.includes($page.url.pathname)}
			<AppShellFooter
				on:signUpButtonClick={() => {
					track('new_user_converted', { location: 'Footer' });
				}}
			/>
		{/if}
	</svelte:fragment>
</AppShell>
