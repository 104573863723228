<script lang="ts">
	import { tippy } from '../../../actions';

	type SocialLink = {
		icon: string;
		name: string;
		tooltip: string;
		url: string;
	};

	const SOCIAL_LINKS: SocialLink[] = [
		{
			// icon: 'ri:twitter-x-line',
			icon: 'line-md:twitter-x',
			name: 'Twitter',
			tooltip: 'Follow us on Twitter',
			url: 'https://twitter.com/tickrr_io/'
		},
		{
			icon: 'ic:baseline-tiktok',
			// icon: 'logos:tiktok-icon',

			name: 'Tiktok',
			tooltip: 'Follow us on Tiktok',
			url: 'https://www.tiktok.com/@tickrr_io'
		},
		{
			icon: 'line-md:instagram',
			// icon: 'skill-icons:instagram',
			name: 'Instagram',
			tooltip: 'Follow us on Instagram',
			url: 'https://instagram.com/tickrr_io/'
		},
		{
			icon: 'line-md:discord',
			name: 'Discord',
			tooltip: 'Join our community on Discord',
			url: 'https://discord.gg/jPaBFjYs' // perma-invite link
		}
	];

	export let classes: string = '';
</script>

<div class="flex gap-x-8 max-tablet:mx-auto {classes}">
	{#each SOCIAL_LINKS as socialLink (socialLink.name)}
		<a
			href={socialLink.url}
			class="transition-all duration-150 hover:text-primary-500"
			use:tippy={{ content: socialLink.tooltip }}
		>
			<iconify-icon icon={socialLink.icon} height="32" />
			<span class="sr-only">{socialLink.name}</span>
		</a>
	{/each}
</div>
