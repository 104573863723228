/* eslint-disable perfectionist/sort-objects */
export type Route = {
	handler?: () => void;
	href?: string;
	icon?: string;
	iconSize?: string;
	label: string;
};

export type SiteMap = {
	[key in 'company' | 'legal' | 'product' | 'solutions']: Route[];
};

// Used in /sitemap.xml/+server.ts && Footer.svelte.
export const SITE_MAP: Omit<SiteMap, 'interactions'> = {
	company: [
		{
			href: '/blog',
			label: 'Blog'
		},
		{
			href: '/faq',
			label: 'FAQ'
		},
		{
			href: '/changelog',
			label: 'Changelog'
		},
		{
			href: 'mailto:support@tickrr.io',
			label: 'Support'
		},

		{
			href: '/subscribe',
			label: 'Pricing'
		}
	],
	product: [
		{
			href: '/features#charts',
			label: 'Charts'
		},
		{
			href: '/features#screener',
			label: 'Screener'
		},
		{
			href: '/features#markets',
			label: 'Markets'
		},
		{
			href: '/features#news',
			label: 'News'
		},
		{
			href: '/features#alerts',
			label: 'Price alerts'
		},
		{
			href: '/extension/install',
			label: 'Chrome extension'
		}
	],
	solutions: [
		{
			href: '/for-day-traders',
			label: 'Day traders'
		},
		{
			href: '/for-crypto-traders',
			label: 'Crypto traders'
		},
		{
			href: '/for-value-investors',
			label: 'Value investors'
		},
		{
			href: '/for-beginners',
			label: 'Beginners'
		}
	],
	legal: [
		{
			label: 'Terms',
			href: '/tos'
		},
		{
			label: 'Privacy',
			href: '/privacy'
		},
		{
			label: 'Cookies',
			href: '/cookies'
		}
	]
};
