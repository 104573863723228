<script context="module" lang="ts">
	export type HoverableHeaderGroup = 'COMPANY' | 'FEATURES' | 'PRICING' | 'SOLUTIONS';
</script>

<script lang="ts">
	import { page } from '$app/stores';
	import { getDrawerStore } from '@skeletonlabs/skeleton';
	import { sticky } from '@tickrr/ui/actions';
	import NameAndLogo from '@tickrr/ui/components/v2/modules/app/NameAndLogo.svelte';
	import { fade } from 'svelte/transition';

	import Dropdown from './Dropdown.svelte';
	import { openMobileNavDrawer } from './helpers';

	const HEADER_GROUP_BUTTON_CLASSES =
		'group flex items-center gap-x-2 px-6 py-4 hover:text-surface-50 hover:underline max-tablet:hidden';

	const drawerStore = getDrawerStore();

	let isMobileDropdownExpanded = false;
	let headerHeight = 0;
	let isStuck = false;

	let activeDropdown: HoverableHeaderGroup | null = null;
	let pendingDropdown: HoverableHeaderGroup | null = null;
	const OPEN_DELAY = 125; // Delay for opening the dropdown
	const CLOSE_DELAY = 100; // Delay for closing the dropdown (keeping the original delay)
	// Tracks the timeout for opening/closing the dropdown
	let dropdownTimeout: ReturnType<typeof setTimeout> | null = null;
	let hoverStartTime: null | number = null;

	function handleDropdown(group: HoverableHeaderGroup | null) {
		if (dropdownTimeout) {
			clearTimeout(dropdownTimeout);
		}

		// If user has hovered out of the dropdown, close it.
		if (group === null) {
			dropdownTimeout = setTimeout(() => {
				activeDropdown = null;
				pendingDropdown = null;
			}, CLOSE_DELAY);
			return;
		}

		// If the user is transitioning from one dropdown to another,
		// move to it immediately.
		if (activeDropdown) {
			activeDropdown = group;
			return;
		}

		// If the user is entering the dropdown hover zone,
		// start the timeout to open the dropdown.
		if (hoverStartTime === null) {
			hoverStartTime = Date.now();
		}

		dropdownTimeout = setTimeout(() => {
			const hoverDuration = Date.now() - (hoverStartTime || 0);
			if (hoverDuration >= OPEN_DELAY && pendingDropdown === group) {
				activeDropdown = group;
			}
			pendingDropdown = null;
		}, OPEN_DELAY);

		pendingDropdown = group;
	}

	function showDropdown(group: HoverableHeaderGroup) {
		handleDropdown(group);
	}

	function hideDropdown() {
		hoverStartTime = null;
		handleDropdown(null);
	}

	function onDropdownMouseEnter() {
		if (dropdownTimeout) {
			clearTimeout(dropdownTimeout);
		}
		pendingDropdown = activeDropdown;
		hoverStartTime = null;
	}

	function onDropdownMouseLeave() {
		hideDropdown();
	}

	function toggleMobileDropdown() {
		openMobileNavDrawer({ drawerStore });
	}
</script>

{#if isMobileDropdownExpanded || activeDropdown}
	<div
		class="pointer-events-none fixed inset-0 z-10 bg-surface-900/70"
		transition:fade={{ duration: 300 }}
	></div>
{/if}

<header
	data-testid="header"
	class="{$page.data.url.pathname === '/' ? 'sticky top-0' : ''}  z-[999] max-tablet:p-2"
	class:bg-surface-900={isStuck}
	use:sticky={{
		onStuck: (e) => (isStuck = e.detail.isStuck),
		topOffset: 0
	}}
>
	<div class="relative w-full px-2 text-surface-50" bind:clientHeight={headerHeight}>
		<div class="mx-auto flex w-full max-w-page items-center justify-between">
			<!-- LEAD -->
			<nav class="flex w-full items-center tablet:gap-x-2 laptop:gap-x-4">
				<a href="/" class="ml-2 !text-xs text-primary-500 tablet:ml-4">
					<NameAndLogo
						classes=""
						logoProps={{
							svgProps: {
								height: 42,
								width: 40
							},
							wrapperProps: {
								classes: '',
								height: 'h-8',
								width: 'w-10'
							}
						}}
					/>
				</a>

				<div class="mx-auto flex">
					<button
						type="button"
						class={HEADER_GROUP_BUTTON_CLASSES}
						on:mouseenter={() => showDropdown('FEATURES')}
						on:mouseleave={hideDropdown}
					>
						<span>Features</span>
						<iconify-icon
							icon="solar:alt-arrow-down-outline"
							class="group-hover:animate-shake-icon"
						></iconify-icon>
					</button>

					<button
						type="button"
						class={HEADER_GROUP_BUTTON_CLASSES}
						on:mouseenter={() => showDropdown('SOLUTIONS')}
						on:mouseleave={hideDropdown}
					>
						<span>Solutions</span>
						<iconify-icon
							icon="solar:alt-arrow-down-outline"
							class="group-hover:animate-shake-icon"
						></iconify-icon>
					</button>

					<button
						type="button"
						class={HEADER_GROUP_BUTTON_CLASSES}
						on:mouseenter={() => showDropdown('COMPANY')}
						on:mouseleave={hideDropdown}
					>
						<span>Company</span>
						<iconify-icon
							icon="solar:alt-arrow-down-outline"
							class="group-hover:animate-shake-icon"
						></iconify-icon>
					</button>

					<!-- <button
						type="button"
						class={HEADER_GROUP_BUTTON_CLASSES}
						on:mouseenter={() => showDropdown('PRICING')}
						on:mouseleave={hideDropdown}
					>
						<span>Pricing</span>
						<iconify-icon
							icon="solar:alt-arrow-down-outline"
							class="group-hover:animate-shake-icon"
						></iconify-icon>
					</button> -->

					<a href="/subscribe" class={HEADER_GROUP_BUTTON_CLASSES}>
						<span>Pricing</span>
					</a>
				</div>
			</nav>

			<!-- SINGLETON DROPDOWN -->
			{#if activeDropdown}
				<Dropdown
					{activeDropdown}
					{hideDropdown}
					on:mouseenter={onDropdownMouseEnter}
					on:mouseleave={onDropdownMouseLeave}
				/>
			{/if}

			<!-- TRAIL -->
			<div class="flex items-center gap-x-2">
				<!-- LOGIN/SIGNUP BUTTONS (If not signed in) -->
				<!-- <SignedOut> -->
				<a href="/signin" class="variant-filled-surface btn btn-sm max-laptop:hidden">
					<span>Log in</span>
				</a>
				<a href="/signup" class="variant-filled btn btn-sm">
					<span class="laptop:hidden">Start trial</span>
					<span class="!ml-0 max-laptop:hidden">Start your free trial</span>
				</a>
				<!-- </SignedOut> -->

				<!-- AVATAR (If signed in) -->
				<!-- 
				NOTE: Conditionally rendering based on whether user is is signed in is NOT supported 
				NOTE: on pre-rendered pages (as are our `/(landing-pages)` pages).
				-->
				<!-- <SignedIn>
					{#if $page.data.isSubscribed}
						<a href="/home" class="variant-filled btn btn-sm">
							<span>Go to app</span></a
						>
					{:else}
						<a href="/subscribe" class="variant-filled btn btn-sm">
							<span>Subscribe</span></a
						>
					{/if}
					<AvatarDropdown avatarRounded="rounded-[8px]" classes="max-tablet:hidden" />
				</SignedIn> -->

				<!-- MOBILE MENU TRIGGER (Always displayed) -->
				<button
					type="button"
					class="btn-icon btn-icon-sm rounded-token tablet:hidden"
					on:click={toggleMobileDropdown}
				>
					<iconify-icon icon="solar:hamburger-menu-outline"></iconify-icon>
					<span class="sr-only">Toggle menu</span>
				</button>
			</div>
		</div>
	</div>
</header>
