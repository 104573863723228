import type { DrawerSettings, DrawerStore } from '@skeletonlabs/skeleton';

export const mobileNavDrawerSettings: DrawerSettings = {
	id: 'header-drawer',
	rounded: 'rounded-none',
	width: 'w-screen'
};

export const openMobileNavDrawer = ({ drawerStore }: { drawerStore: DrawerStore }) => {
	drawerStore.open({ ...mobileNavDrawerSettings });
};
