<script lang="ts">
	import { SITE_MAP } from '@tickrr/lib/constants';
	import SocialButtons from '@tickrr/ui/components/v2/elements/SocialButtons.svelte';
	import NameAndLogo from '@tickrr/ui/components/v2/modules/app/NameAndLogo.svelte';
	import { createEventDispatcher } from 'svelte';

	import RouteGroup from './FooterRouteGroup.svelte';

	const dispatch = createEventDispatcher<{ signUpButtonClick: void }>();

	function dispatchSignUpButtonClick() {
		dispatch('signUpButtonClick');
	}
</script>

<div class="flex w-full flex-col border-t border-base">
	<!-- UPPER -->
	<div class="mx-auto flex w-full max-w-7xl flex-col gap-x-4 px-8 py-4 tablet:flex-row">
		<!-- CTA SECTION -->
		<div class="flex max-w-xs flex-col gap-4 pt-4 max-tablet:mx-auto max-tablet:py-4">
			<!-- Name And Logo -->
			<a href="/">
				<NameAndLogo />
			</a>

			<!-- Description -->
			<p class="max-tablet:text-center">
				Trade smarter with Tickrr, the world's most advanced charting and market research
				platform.
			</p>
			<!-- Join Now Button -->
			<a
				href="/signup"
				class="variant-filled btn w-fit px-8 max-tablet:mx-auto"
				on:click={dispatchSignUpButtonClick}
			>
				<span> Start free trial </span>
			</a>
			<!-- Social Buttons -->
			<SocialButtons classes="mt-8" />
		</div>

		<div class="tablet:grow" />

		<!-- ROUTE GROUPS -->
		<nav
			class="max-tablet:grid max-tablet:grid-cols-2 max-tablet:gap-4 tablet:flex tablet:justify-evenly tablet:gap-x-16"
		>
			<RouteGroup routes={SITE_MAP.product} testid="footer__product" title="Product" />
			<RouteGroup
				routes={SITE_MAP.solutions}
				testid="footer__solutions"
				title="Solutions For"
			/>
			<!-- <RouteGroup routes={SITE_MAP.app} testid="footer__feeds" title="App" /> -->
			<RouteGroup routes={SITE_MAP.legal} testid="footer__interactions" title="Legal" />
		</nav>
	</div>

	<!-- LOWER -->
	<div class="mx-auto flex w-full max-w-7xl items-center gap-4 p-4 text-sm max-tablet:flex-col">
		<span class="font-bold max-tablet:text-center">LawServ, Inc.</span>

		<nav class="flex gap-x-1 tablet:gap-x-2">
			{#each SITE_MAP.legal as { href, label }, idx (label)}
				<a {href} class="block px-2 hover:anchor max-tablet:w-full max-tablet:text-center">
					<span>
						{label}
					</span>
				</a>

				<span class:!hidden={idx === SITE_MAP.legal.length - 1}> &#124; </span>
			{/each}
		</nav>
	</div>
</div>
