<script lang="ts">
	import type { Route } from '@tickrr/lib/constants';

	export let title: string;
	export let routes: Route[];
	export let testid: string;
</script>

<div data-testid={testid} class="py-4">
	<span class="mb-2 flex flex-col font-bold tracking-tight text-surface-50">
		{title}
	</span>

	<!-- Items -->
	<ol class="list space-y-3">
		{#each routes as route (route)}
			<li class="flex">
				{#if route.handler}
					<button
						type="button"
						class="space-x-2 underline-offset-4 hover:anchor"
						on:click={route.handler}
					>
						<span>{route.label}</span>

						{#if route.icon}
							<iconify-icon inline icon={route.icon} />
						{/if}
					</button>
				{:else if route.href}
					<a href={route.href} class="space-x-2 underline-offset-4 hover:anchor">
						<!-- eslint-disable-next-line svelte/no-at-html-tags -->
						<span> {@html route.label}</span>

						{#if route.icon}
							<iconify-icon
								inline
								icon={route.icon}
								height={route.iconSize ?? '16px'}
							/>
						{/if}
					</a>
				{/if}
			</li>
		{/each}
	</ol>
</div>
