<script lang="ts">
	import { elasticOut } from 'svelte/easing';
	import { fly } from 'svelte/transition';

	export let label: string;
	export let description: string;
	export let href: string;
	export let icon: string;

	let isHovered = false;
</script>

<a
	{href}
	class="p-2 shadow-sm transition-all duration-300 ease-in-out rounded-token hover:-translate-y-0.5 hover:bg-surface-600 hover:shadow-md"
	on:mouseenter={() => (isHovered = true)}
	on:mouseleave={() => (isHovered = false)}
	on:click
>
	<h3 class="mb-1 flex items-center gap-x-2 text-2xl font-black text-surface-50">
		<iconify-icon {icon} class="text-surface-50"></iconify-icon>
		<span>{label}</span>
	</h3>
	<div class="flex items-center justify-between gap-x-2">
		<p class="max-w-64 whitespace-normal text-sm text-surface-100">{description}</p>
		<span class="size-8 !shrink-0">
			{#if isHovered}
				<span
					class="flex h-full w-full items-center justify-center rounded-full bg-surface-500"
					in:fly={{ duration: 800, easing: elasticOut, x: -20 }}
				>
					<iconify-icon icon="solar:arrow-right-bold" class="h-fit" />
				</span>
			{/if}
		</span>
	</div>
</a>
