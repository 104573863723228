<script lang="ts">
	import { page } from '$app/stores';
	import BlogImage from '$images/art/anime-library-2.jpeg?enhanced';
	import DayTradersImage from '$images/art/neo-1.jpeg?enhanced';
	import BeginnerInvestorsImage from '$images/art/neo-2.jpeg?enhanced';
	import CryptoTradersImage from '$images/art/neo-6.jpeg?enhanced';
	import ValueInvestorsImage from '$images/art/neo-7.jpeg?enhanced';
	import LearnMoreImage from '$images/art/neo-10.jpeg?enhanced';
	import FAQImage from '$images/art/spaceman-studying-1.jpeg?enhanced';
	import ChartPageImage from '$images/landingpage/chart-page__full-screen.png?enhanced';
	import NewsPageImage from '$images/landingpage/hero/news-page.png?enhanced';
	import ScreenerPageImage from '$images/landingpage/hero/screener-page.png?enhanced';
	import MarketsPageImage from '$images/landingpage/markets-page__cropped.png?enhanced';
	import StripeSupportedPaymentProcessors from '@tickrr/ui/components/v2/modules/stripe/StripeSupportedPaymentProcessors.svelte';
	import { cubicInOut } from 'svelte/easing';
	import { fly } from 'svelte/transition';

	import type { HoverableHeaderGroup } from './Header.svelte';

	import LargeItemCard from './LargeItemCard.svelte';
	import SmallItemCard from './SmallItemCard.svelte';

	const DROPDOWN_DIMENSIONS: Record<HoverableHeaderGroup, { height: number; width: number }> = {
		FEATURES: { height: 806, width: 658 },
		SOLUTIONS: { height: 506, width: 658 },
		// eslint-disable-next-line perfectionist/sort-objects
		COMPANY: { height: 310, width: 858 },
		PRICING: { height: 640, width: 640 }
	};

	export let activeDropdown: HoverableHeaderGroup | null = null;
	export let hideDropdown: () => void;

	$: ({ height, width } = activeDropdown
		? DROPDOWN_DIMENSIONS[activeDropdown]
		: { height: 0, width: 0 });
</script>

<div
	aria-label={`${activeDropdown} dropdown menu`}
	data-testid="header-dropdown"
	role="menu"
	tabindex="-1"
	class="absolute left-1/2 top-full z-50 mt-1 -translate-x-1/2 border border-base bg-surface-900 p-2 shadow-lg rounded-container-token"
	style="
		height: {height}px; 
		width: {width}px;
		transition-property: width,height,opacity,transform; 
		transition-timing-function: cubic-bezier(0,0,.2,1);
		will-change: width,height,opacity,transform;
		transition-duration: 200ms;
	"
	on:mouseenter
	on:mouseleave
	in:fly={{ duration: 300, easing: cubicInOut, y: 200 }}
>
	<div class="w-fit min-w-[640px]">
		{#if activeDropdown === 'FEATURES'}
			<div class="grid grid-cols-2 gap-2">
				<LargeItemCard
					classes="col-span-2 border border-base"
					description="TickrrCharts&trade; are the best and most accessible charts on the market"
					href="/features#charts"
					label="Charts"
					picture={ChartPageImage}
					on:click={hideDropdown}
				/>
				<LargeItemCard
					classes="border border-base"
					description="A powerful screener to help you identify opportunities instantly"
					href="/features#screener"
					label="Screener"
					picture={ScreenerPageImage}
					on:click={hideDropdown}
				/>
				<LargeItemCard
					classes="border border-base"
					description="Get a birds-eye view of the market at any time"
					href="/features#markets"
					label="Markets"
					picture={MarketsPageImage}
					on:click={hideDropdown}
				/>
				<LargeItemCard
					classes="border border-base"
					description="Get the latest news and insights to stay informed and invest smarter"
					href="/features#dashboard"
					label="News"
					picture={NewsPageImage}
					on:click={hideDropdown}
				/>
				<div class="flex flex-col gap-y-2">
					<SmallItemCard
						icon="solar:list-outline"
						description="Share investing ideas and connect with the Tickrr community"
						href="/features#misc"
						label="Forums"
						on:click={hideDropdown}
					/>
					<SmallItemCard
						icon="solar:bell-linear"
						description="Create price alerts to get notified when any asset moves"
						href="/features#misc"
						label="Alerts"
						on:click={hideDropdown}
					/>
					<SmallItemCard
						icon="logos:chrome-web-store"
						description="Access Tickrr on the go with our Chrome extension"
						href="/extension/install"
						label="Chrome Extension"
						on:click={hideDropdown}
					/>
				</div>
			</div>
		{:else if activeDropdown === 'SOLUTIONS'}
			<div class="grid grid-cols-2 gap-2">
				<LargeItemCard
					description="Discover how Tickrr can benefit day traders"
					href="/for-day-traders"
					label="Day Traders"
					picture={DayTradersImage}
					on:click={hideDropdown}
				/>
				<LargeItemCard
					description="Discover how Tickrr can benefit crypto traders"
					href="/for-crypto-traders"
					label="Crypto Traders"
					picture={CryptoTradersImage}
					on:click={hideDropdown}
				/>
				<LargeItemCard
					description="Discover how Tickrr can benefit value investors"
					href="/for-value-investors"
					label="Value Investors"
					picture={ValueInvestorsImage}
					on:click={hideDropdown}
				/>
				<LargeItemCard
					description="Discover how Tickrr can benefit beginner investors"
					href="/for-beginners"
					label="Beginner Investors"
					picture={BeginnerInvestorsImage}
					on:click={hideDropdown}
				/>
			</div>
		{:else if activeDropdown === 'COMPANY'}
			<div class="grid w-[840px] grid-cols-3 gap-2">
				<LargeItemCard
					description="Read the latest news and insights from the Tickrr team"
					href="/blog"
					label="Blog"
					picture={BlogImage}
					on:click={hideDropdown}
				/>
				<LargeItemCard
					description="Find answers to common questions about Tickrr"
					href="/faq"
					label="FAQ"
					picture={FAQImage}
					on:click={hideDropdown}
				/>
				<div class="flex flex-col gap-2">
					<SmallItemCard
						icon="solar:code-file-linear"
						description="See what's new and what's changed in Tickrr"
						href="/changelog"
						label="Changelog"
						on:click={hideDropdown}
					/>
					<SmallItemCard
						icon="solar:danger-triangle-outline"
						description="Get help and support from the Tickrr team"
						href="mailto:support@tickrr.io"
						label="Support"
						on:click={hideDropdown}
					/>
					<SmallItemCard
						icon="line-md:twitter-x-alt"
						description="Stay always up-to-date by following us on X"
						href="https://x.com/tickrr_io"
						label="Follow us on X"
						on:click={hideDropdown}
					/>
				</div>
			</div>
		{:else if activeDropdown === 'PRICING'}
			<div class="flex min-w-[640px] items-center justify-center text-surface-200">
				<div class="mx-auto flex w-full flex-col items-center p-6 text-xl transition-all">
					<span class="mb-2">Tickrr Pro | Monthly</span>
					<span class="mb-2 text-center font-sans text-6xl font-black text-surface-50"
						>14 days free</span
					>
					<span class="mb-4">Then $39 per month</span>

					<a
						href={$page.data.profile ? '/checkout' : '/subscribe'}
						class="variant-filled btn w-full max-w-xs flex-col gap-y-2 py-4 font-sans font-bold"
					>
						<span>Start your free trial</span>
					</a>
					<StripeSupportedPaymentProcessors classes="mt-3" />
				</div>
				<LargeItemCard
					classes="!min-h-96"
					description="Click to find out more about Tickrr Pro"
					href="/subscribe"
					label="Learn more"
					picture={LearnMoreImage}
					on:click={hideDropdown}
				/>
			</div>
		{/if}
	</div>
</div>
