<script lang="ts">
	import type { Picture } from '$lib/types/Picture';

	import { elasticOut } from 'svelte/easing';
	import { fly } from 'svelte/transition';

	export let label: string;
	export let description: string;
	export let href: string;
	export let picture: Picture;
	export let classes: string = '';

	let isHovered = false;
</script>

<a
	{href}
	class="group relative flex min-h-60 min-w-60 flex-col justify-end overflow-hidden rounded-token {classes}"
	on:mouseenter={() => (isHovered = true)}
	on:mouseleave={() => (isHovered = false)}
	on:mouseenter
	on:mouseleave
	on:click
>
	<enhanced:img
		alt={label}
		loading="lazy"
		src={picture}
		class="absolute inset-0 h-full w-full object-cover object-center brightness-90 transition-transform duration-500 ease-in-out group-hover:scale-[1.02] group-hover:brightness-105"
	/>
	<div class="z-10 w-full bg-gradient-to-t from-black via-black/80 to-transparent p-2">
		<h3 class="mb-1 text-2xl font-black text-surface-50">{label}</h3>
		<div class="flex items-center justify-between gap-x-2">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			<p class="max-w-64 whitespace-normal text-sm text-surface-100">{@html description}</p>
			<span class="size-8 !shrink-0">
				{#if isHovered}
					<span
						class="flex h-full w-full items-center justify-center rounded-full bg-surface-500"
						in:fly={{ duration: 800, easing: elasticOut, x: -20 }}
					>
						<iconify-icon icon="solar:arrow-right-bold" class="h-fit" />
					</span>
				{/if}
			</span>
		</div>
	</div>
</a>
